/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/30 16:26
 * @description
 *   invite.ts of WeTrade
 */
import { getLangIndex, keymap } from '@/config'
import useRequest from '@/hooks/useRequest'
import { inviteRegister } from '@/pages/invite/invite.api'
import { localGet } from 'essential/store/localStore'
import { useRoute } from 'vue-router'

export const makeShareUrl = (redirect: string) => {
  return `${location.origin}?from=share&lang=${localGet(
    keymap.label.language)}&shareDest=${encodeURIComponent(redirect)}`
}

export const makeShareQuery = () =>
  `invitationCode=${localGet(keymap.user.invitationCode)}&language=${getLangIndex()}`

export const useInvite = () => {
  const route = useRoute()
  const { orderId } = route.query
  const [request, progress] = useRequest(inviteRegister)
  const commit = (data: Data) => {
    return request({
      ...data,
      channelFlag: orderId ? 'profitShare' : 'inviteFriends',
    })
  }
  return { commit, progress }
}

export const share = (url: string) => {
  (window as any).FB.ui({
    method: 'share',
    href: url,
  })
}
