import requestResult from '@/common/request/request'
import { Position } from '@/modules/trade/trade.api'

export const readShareInfo = requestResult<{ invitatedUsers: string[] }>('/api/user/share/info')

export const inviteRegister = requestResult('/api/user/share/register')

export const readCCList = requestResult('/api/user/country/list')

export const requestCode = (data: Data) => requestResult('/api/sms/send')({
  ...data,
  type: 1,
})

export const readPositionDetail = requestResult<Position>('/api/order/mt/history/detail')
